<form
  [formGroup]="formArray"
  class="table--holder overflow-x-auto overflow-y-hidden"
  [class.overflow-visible]="overflowVisible"
  [class.preview-mode-width]="flowType === 'previewFlow' && selectedViewType === 2"
>
  <div *ngIf="isHidden" class="d-flex align-items-center automation-warning p-10 mt-10 mb-10">
    <app-hide-from-end-users [text]="'This page will be hidden from end-users.'"></app-hide-from-end-users>
  </div>
  <table class="fonts-col__body-copy">
    <tr *ngFor="let row of page.moduleFlowPage?.children; index as rowIndex">
      <td
        class="data-width"
        *ngFor="let cell of row; index as colIndex"
        [ngClass]="isError(cell.formattedControlContent) ? ['table--cell-error'] : cell.style"
        [attr.title]="isError(cell.formattedControlContent) ? 'Result of formula can\'t be calculated' : null"
      >
        <span [ngSwitch]="cell.type">
          <span *ngSwitchCase="cellTypes.dropdown" class="d-block cell--padding-3">
            <ng-container [ngSwitch]="disableInputs">
              <ey-input
                *ngSwitchCase="true"
                [formControl]="asDisabledInputControl(formArray.controls[rowIndex].controls[colIndex])"
                type="text"
                [meta]="meta.textInput"
                extra="none"
              ></ey-input>
              <ey-drop-down
                *ngSwitchDefault
                [noInfo]="true"
                [enableTwoLineCutOffForDropDownItem]="true"
                [values]="cell.properties.options"
                [meta]="meta.dropdown"
                [formControl]="formArray.controls[rowIndex].controls[colIndex]"
              ></ey-drop-down>
            </ng-container>
          </span>
          <span *ngSwitchCase="cellTypes.textInput" class="d-block cell--padding-3">
            <ey-input [formControl]="formArray.controls[rowIndex].controls[colIndex]" type="text" [meta]="meta.textInput" extra="none"></ey-input>
          </span>
          <span *ngSwitchCase="cellTypes.numberInput" class="d-block cell--padding-3">
            <ey-input
              [tableCellFormatOnBlur]="cell"
              #tableCellFormatOnBlur="tableCellFormatOnBlur"
              [formControl]="formArray.controls[rowIndex].controls[colIndex]"
              type="text"
              [meta]="meta.textInput"
              extra="none"
            ></ey-input>
          </span>
          <span *ngSwitchDefault class="p-10" [innerHTML]="getValue(cell, rowIndex, colIndex)"></span>
        </span>
      </td>
    </tr>
  </table>
</form>
