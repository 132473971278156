import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface Config {
  buildInformation: {
    BuildID: string;
    EnvironmentName: string;
  };
  environment: {
    scopeUri: string[];
    tenantId: string;
    clientId: string;
    redirectUrl: string;
    postLogoutRedirectUrl: string;
    authority: string;
    knownAuthorities: string[];
    instrumentationKey: string;
  };
}

export const CONFIG_URL = 'assets/config/config.json';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private config: Config;
  private configuration$: Observable<Config>;

  constructor() {
    this.configuration$ = from(fetch(CONFIG_URL)).pipe(
      switchMap((r) => from(r.json())),
      shareReplay(1),
    );
  }

  setConfig(x: Config): void {
    this.config = x;

    this.config.environment.scopeUri = [`api://${this.config.environment.clientId}/access_as_user`];
    this.config.environment.postLogoutRedirectUrl = `${this.config.environment.redirectUrl}/logout`;
  }

  getConfig(): Config {
    return this.config;
  }

  getEnviromentName(): Observable<string> {
    return this.configuration$.pipe(map((c) => c.buildInformation.EnvironmentName));
  }

  isProductionEnvironment(): boolean {
    return this.config.buildInformation.EnvironmentName === 'Production';
  }

  getBuildNumber(): Observable<string> {
    return this.configuration$.pipe(map((c) => c.buildInformation.BuildID));
  }

  getAppInsightsInstrumentationKey(): Observable<string> {
    return this.configuration$.pipe(map((c) => c.environment.instrumentationKey));
  }

  getSupportEmail(): Observable<string> {
    return of(environment.supportEmail);
  }
}
