<ey-modal-template>
  <div header>
    <h2 class="modal__title">Allowed file types:</h2>
  </div>
  <div body>
    <h4 class="fonts-col__heading-4 m-0">1. Documents:</h4>
    <ul>
      <li class="eyfontlight">
        doc, docx, xls, xlsx, ppt, .pptx, .pdf, .odt, .odp, .ods, .csv, .pps, .ppsx, .txt, .xml, epub, .json, .css, .pages, .numbers, .key, .htm,
        .xhtml, .html, .rtf, .mdb, .msg, .eml, .emlx, .gsheet
      </li>
    </ul>
    <h4 class="fonts-col__heading-4 m-0">2. Images:</h4>
    <ul>
      <li class="eyfontlight">.jpg, .jpeg, .png, .tiff, .bmp, .psd, .svg, .tif, .raw</li>
    </ul>
    <h4 class="fonts-col__heading-4 m-0">3. Video/music:</h4>
    <ul>
      <li class="eyfontlight">.avi, .wav, .mp3, .mp4, .wmv</li>
    </ul>
    <h4 class="fonts-col__heading-4 m-0">4. Archive:</h4>
    <ul>
      <li class="eyfontlight">zip, .7zip, .rar ,.zipx, .7z</li>
    </ul>
    <h4 class="fonts-col__heading-4 m-0">5. Alteryx (Analytics tool):</h4>
    <ul>
      <li class="eyfontlight">.yxmd, .yxwz</li>
    </ul>
  </div>
  <div class="w-100 d-flex justify-content-center" footer>
    <button class="btn btn--secondary" (click)="onDismiss()">Dismiss</button>
  </div>
</ey-modal-template>
