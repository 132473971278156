<form [formGroup]="scoresForm">
  <ng-container formArrayName="scores">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let fa of optionsArray.controls; index as i">
        <ng-container [formGroupName]="i">
          <div class="example-box" cdkDrag (cdkDragStarted)="dragStart($event)">
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <app-option
              [disableDelete]="isOptionDeleteDisabled"
              [options]="options"
              [minDisplayOrder]="minDisplayOrder"
              [maxDisplayOrder]="maxDisplayOrder"
              formControlName="value"
              (optionLostFocus)="onOptionLostFocus(i)"
              (actionClick)="onAction($event)"
              class="d-block mb-10"
            ></app-option>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <a *ngIf="optionsArray?.controls?.length < 20" (click)="onAddOption()" class="add--option my-10"
    ><i class="emp-icon icon-ico_plus"></i><span class="mb-auto mt-auto">Add Option</span></a
  >
</form>
