<ey-froala-content-wrapper [editorContent]="body"></ey-froala-content-wrapper>

<span *ngIf="flowPage.mode === 1 && uploadedFiles?.length < 1">- Not Uploaded -</span>
<span *ngIf="flowPage.mode === 2">There is one document for you to download</span>

<ng-container *ngIf="flowPage.mode === 1">
  <ul class="ps-0 mt-10">
    <li class="segments d-flex" *ngFor="let f of uploadedFiles">
      <span class="flex-grow-1">{{ f.name }}</span>
      <i title="Download File" role="button" (click)="downloadUploadedExcelFile(f)" class="icon-ico_download emp-icon"></i>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="flowPage.mode === 2">
  <ul class="ps-0 mt-10">
    <li class="segments d-flex">
      <span class="flex-grow-1">Download your customized Excel file here</span>
      <i title="Download File" role="button" (click)="downloadOutFile()" class="icon-ico_download emp-icon"></i>
    </li>
  </ul>
</ng-container>

<p *ngIf="!!flowPage.connectedTemplate?.prepopulatedTemplate">
  Used prepopulated template <b>{{ flowPage.connectedTemplate?.prepopulatedTemplate.name }}</b>
</p>
